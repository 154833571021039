import request from '@/utils/request'
const BASE_URL = '/wjhhjk'

export class Scheme {
  /**
   * @description  根据方案id 和 河湖对象类型查找指标列表和填报数据信息
   * @author hu_ty
   * @since
   * @param {number} rlType 河湖对象类型
   * @param {number} id 方案id（schemeId）
   *
   */
  static getTargetList = (rlType, id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/scheme/findScoreDetail/` + rlType + '/' + id
    })
  }
  /**
   * @description  根据方案id查找数据信息
   * @author hu_ty
   * @since
   * @param {number} id 方案id（schemeId）
   *
   */
  static getInfoById = ( id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/scheme/findById/`  + id
    })
  }

  /**
   * @description  通过项目id获取方案列表
   * @author hu_ty
   * @since
   * @param {*}
   *
   */
  static getListByProId = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/scheme/listPage`,
      data: params
    })
  }
}

/**
 * @description  站点信息类
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Site {
  static getList = (param) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/site/listPage`,
      data: param
    })
  }
  /**
   * @description 添加水质监测站点信息
   * @param {object} params 水质监测站点信息
   * @returns
   */
  static szSiteSave = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/site/saveTypeOfWaterQuality`,
      data: params
    })
  }
}

/**
 * @description  河湖对象类
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Rlobject {
  static getInfo = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/obj/findById/` + id
    })
  }
}
